$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; .discourse-local-date {
  svg,
  span {
    pointer-events: none;
  }

  &.cooked-date {
    color: var(--primary);
    cursor: pointer;
    border-bottom: 1px dashed var(--primary-medium);

    .d-icon {
      color: var(--primary);
    }

    &.past {
      border-bottom-color: var(--primary-low-mid);
    }

    &.past[data-countdown] {
      color: var(--primary-medium);
    }
  }
}

.locale-dates-previews {
  max-width: 250px;

  .preview {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 0;

    .timezone {
      font-weight: 700;
    }

    &.current {
      background: var(--tertiary-low);
    }
  }
}

.download-calendar {
  text-align: right;
  cursor: pointer;
  margin-top: 0.5em;
}
